import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'Dashboard',
    isTitle: true
  },
  {
    id: 2,
    label: 'DASHBOARD',
    icon: 'uil-home-alt',
    link: '/',
  },
  {
    id: 3,
    label: 'Notary Profile',
    icon: 'mdi mdi-account-cog-outline',
    subItems: [
      {
        id: 1,
        label: 'Profile',
        link: '/personal-info',
        parentId: 3
      },
      {
        id: 2,
        label: 'Documents',
        link: '/notary-documents',
        parentId: 3
      },
      {
        id: 3,
        label: 'Availability',
        link: '/notary-availability',
        parentId: 3
      },
      {
        id: 4,
        label: 'Services',
        link: '/notary-services',
        parentId: 3
      }
    ]
  }
];

