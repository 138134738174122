import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }


  Login(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/notary/login`, body);
  }

  Register(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/notary/register`, body);
  }

  EmailExists(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/notary/check-email`, body);
  }

  VerifyEmail(code: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/auth/notary/verification-code/${code}`);
  }

  ForgotPassword(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/auth/forgot-password`, body);
  }

  ChangePassword(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/notary/auth/changePassword`, body);
  }

  GetConfiguration(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/client/client/get-configurations`);
  }

  UpdateConfiguration(body: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}/client/client/update-configurations`, body);
  }

  GetProfile() {
    return this.http.get(`${environment.baseUrl}/client/dashboard/client-profile`);
  }

  GetParent(code: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/get-parent/${code}`);
  }
  isLoggedIn(): boolean {
    return !!localStorage.getItem('notaryToken');
  }

  Logout(): Observable<any> {
    localStorage.removeItem('notaryToken');
    localStorage.removeItem('NotaryUser');
    localStorage.removeItem('NotaryUserRole');

    this.router.navigate(['/account/login'])
    return this.http.get(`${environment.baseUrl}/client/auth/logout`);
  }

}

