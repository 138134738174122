<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        {{year}} © Notary 24x7.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Crafted By <i class="mdi mdi-heart text-danger"></i> by <a href="" target="_blank"
            class="text-reset">DreamerzXTech</a>
        </div>
      </div>
    </div>
  </div>
</footer>